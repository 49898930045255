import React, { useEffect, useMemo, useState } from 'react'
import { ExamStatusType, IExam, IExamSchedule } from '../../types/exam.d';
import { serverGetExamData, serverGetExamSchedule, serverGetExamScheduleByBatchId } from '../../services/serverApi';
import {
    type MRT_ColumnDef,
    type MRT_Row,
    useMaterialReactTable,
} from 'material-react-table';
import { Box, IconButton, Tooltip } from '@mui/material';
import { getStringDateTime } from '../../utils/consts/exam';
import moment from 'moment';
import { ExamStatus, StartExamButton } from './ExamTable.styled';
import { getExamStatusBgColor, getExamStatusColor } from '../../utils/helpers/global';
import Countdown from '../../components/Countdown/Countdown';
import { ICandidate } from '../../types/candidate';

const useExamTable = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [examScheduleData, setExamScheduleData] = useState<IExamSchedule[]>([]);
    const [openNoticeDialog, setOpenNoticeDialog] = useState<boolean>(false);
    const [openProfileMatchDialog, setOpenProfileMatchDialog] = useState<boolean>(false);
    const [selectedExamData, setSelectedExamData] = useState<IExamSchedule>();

    const getExamScheduleData = async () => {
        try {
            setLoading(true);
            const adminDataString = localStorage.getItem('candidate');
            if (adminDataString) {
                const adminData = JSON.parse(adminDataString);
                const data = await serverGetExamScheduleByBatchId(String(adminData?.batchId), adminData?._id);
                setExamScheduleData(data?.data);
            }
        } catch (error) {
            console.log(error);
            setExamScheduleData([]);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const handleOpenNoticeDialog = (data: any) => {
        setOpenNoticeDialog(true);
        setSelectedExamData(data)
    }

    const handleCloseNoticeDialog = () => {
        setOpenNoticeDialog(false);
        setSelectedExamData(undefined);
    }

    const handleOpenProfileMatchDialog = () => {
        setOpenNoticeDialog(false);
        setOpenProfileMatchDialog(true);
    }

    const handleCloseProfileMatchDialog = () => {
        setOpenProfileMatchDialog(false);
    }

    const columns = useMemo<MRT_ColumnDef<IExamSchedule>[]>(
        () => [
            {
                accessorKey: "name",
                header: "Exam Name",
                Cell: ({ row }: any) => {
                return (
                <Box sx={{ display: 'flex', gap: '2ch', alignItems: 'center' }}>
                    {row?.original?.examData?.name}
                </Box>
                )
                },
            },
            {
                accessorKey: "starDate",
                header: "Start Date",
                Cell: ({ row }: any) => {
                return (
                <Box sx={{ display: 'flex', gap: '2ch', alignItems: 'center' }}>
                    {getStringDateTime(moment(row?.original?.startDate).format('YYYY-MM-DD'), String(row?.original?.startTime))}
                </Box>
                )
                },
            },
            {
                accessorKey: "endDate",
                header: "End Date",
                Cell: ({ row }: any) => {
                return (
                <Box sx={{ display: 'flex', gap: '2ch', alignItems: 'center' }}>
                    {getStringDateTime(moment(row?.original?.endDate).format('YYYY-MM-DD'), row?.original?.endTime)}
                </Box>
                )
                },
            },
            {
                accessorKey: "pasingMark",
                header: "Passing Marks",
                Cell: ({ row }: any) => {
                return (
                <Box sx={{ display: 'flex', gap: '2ch', alignItems: 'center' }}>
                    {row?.original?.examData?.passingMark}
                </Box>
                )
                },
            },
            {
                accessorKey: "totalMark",
                header: "Total Marks",
                Cell: ({ row }: any) => {
                return (
                <Box sx={{ display: 'flex', gap: '2ch', alignItems: 'center' }}>
                    {row?.original?.examData?.totalMark}
                </Box>
                )
                },
            },
            {
                accessorKey: "status",
                header: "status",
                Cell: ({ row }: any) => {
                return (
                <Box sx={{ display: 'flex', gap: '2ch', alignItems: 'center' }}>
                    <ExamStatus bgColor={getExamStatusBgColor(row?.original?.examScheduleStatusData?.status)} color={getExamStatusColor(row?.original?.examScheduleStatusData?.status)}>{row?.original?.examScheduleStatusData?.status}</ExamStatus>
                </Box>
                )
                },
            },
        ],
      []
    );

    const table = useMaterialReactTable({
        columns,
        data: examScheduleData,
        enableEditing: true,
        positionActionsColumn: "last",
        enablePagination: false,
        enableBottomToolbar: false,
        state: { isLoading: loading },
        enableStickyHeader: true,
        muiTableContainerProps: { sx: { maxHeight: "65vh" } },
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                {row?.original?.examScheduleStatusData?.status === ExamStatusType.Pending && (
                    <IconButton onClick={() => handleOpenNoticeDialog(row?.original)}>
                        <StartExamButton>Start Exam</StartExamButton>
                    </IconButton>
                )}
                {row?.original?.examScheduleStatusData?.status === ExamStatusType?.Upcoming && (
                    <Countdown dateString={moment(row?.original?.startDate).format('YYYY-MM-DD')} timeString={String(row?.original?.startTime)} examScheduleId={row?.original?._id?.toString()} getExamScheduleData={getExamScheduleData} />
                )}
                {/* {row?.original?.status === ExamStatusType.Completed && (
                    <IconButton onClick={() => handleOpenNoticeDialog(row?.original)}>
                        <StartExamButton>Start Exam</StartExamButton>
                    </IconButton>
                )} */}
            </Box>
        ),
    });

    useEffect(() => {
        getExamScheduleData()
    }, [])

    return {
        table,
        openNoticeDialog,
        handleCloseNoticeDialog,
        selectedExamData,
        handleOpenProfileMatchDialog,
        openProfileMatchDialog,
        handleCloseProfileMatchDialog
    }
}

export default useExamTable