import styled from "styled-components";

export const ExamModuleContainer = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
height: 100%;
width: 100%;
`
export const ExamModuleHeaderContainer = styled.div`
height: 40.5px;
width: 100%;
background: #3F87FE;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0px 10px 0px 10px;
`
export const ExamName = styled.div`
font: Inter;
font-weight: 400;
font-size: 13.95px;
color: #ffffff;
`
export const ExamTime = styled.div`
display: flex;
align-items: center;
gap: 5px;
color: #ffffff;
`
export const ExamQuestionContainer = styled.div`
border: 1px solid #98C8E6;
height: calc(100% - 72px);
width: calc(100% - 237px);
display: flex;
flex-direction: column;
gap: 40px;
`
export const ExamQuestionHeaderContainer = styled.div`
display: flex;
align-items: center;
gap: 20px;
padding-left: 20px;
padding-right: 20px;
padding-top: 20px;
`
export const ExamQuestionNote = styled.div`
font: Inter;
font-weight: 400;
font-size: 15px;
color: #FF0000;
`
export const ExamQuestionFooterContainer = styled.div`
height: 98.7px;
width: 100%;
background: #98C8E6;
display: flex;
align-items: center;
justify-content: flex-end;
gap: 20px;
padding: 20px;
`
export const ExamQuestionBodyContainer = styled.div`
height: 380px;
padding-left: 20px;
padding-right: 20px;
`
export const ExamQuestionWrapper = styled.div`
display: flex;
align-items: flex-start;
width: 100%;
/* height: calc(100% - 40.5px);
 */
 /* height: 100%; */
`
export const ExamQuestionRightContainer = styled.div`
width: 237px !important;
display: flex;
flex-direction: column;
`
export const ExamCandidateContainer = styled.div`
width: 100%;
height: 160px;
background: #ffffff;
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
gap: 10px;
`
export const ExamQuestionListConatiner = styled.div`
width: 100%;
height: 300px;
background: #EDF5FA;
display: flex;
align-items: flex-start;
flex-wrap: wrap;
gap: 10px;
padding: 20px;
align-content: flex-start;
`
export const ExamCameraContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
height: 150px;
`
export const ExamQuestionCount = styled.div<{ isFill: boolean }>`
width: 26px;
height: 26px;
border-radius: 4px;
display: flex;
align-items: center;
justify-content: center;
background-color: ${({ isFill }) => isFill ? '#4CAF50' : '#E94235'};
color: #ffffff;
cursor: pointer;
`
export const CandidateImg = styled.img`
width: 63px;
height: 63px;
`
export const CandidateName = styled.div`
font: Inter;
font-weight: 400;
font-size: 14.53px;
color: #3F87FE;
`
export const QuestionContainer = styled.div`
display: flex;
align-items: flex-start;
gap: 10px;
`
export const QuestionLabel = styled.div`
font: Inter;
font-weight: 700;
font-size: 15px;
color: #3F87FE;
white-space: nowrap;
`
export const Question = styled.div`
font: Inter;
font-weight: 700;
font-size: 15px;
color: #000000;
`
export const ExamQuestionBodyWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
height: 100%;
`
export const QuestionOptionContainer = styled.div`
`
export const QuestionAnsInputBoxContainer = styled.div`
`
export const QuestionAnsInput = styled.textarea`
height: 280px;
width: 100%;
max-width: 850px;
padding: 10px;
max-height: 280px;
`