import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { ExamCompletedDialogBodyContainer, ExamCompletedDialogBodyTitle, ExamCompletedDialogContainer, ExamCompletedDialogDescription, ExamCompletedDialogHeader, ExamCompletedDialogHeaderContainer, ExamCompletedMarkContainer, ExamCompletedPassingMark, ExamCompletedTotalMark } from './ExamCompletedDialog.styled';
// const similarity = require('string-similarity');
import similarity from 'string-similarity'

interface Props {
  open: boolean;
  handleClose: () => void;
  examScheduleData: any;
  examQuestionData: any;
}

const ExamCompletedDialog: React.FC<Props> = ({ open, handleClose, examScheduleData, examQuestionData }) => {
  const calculateSubjectiveMarks = (
    question: string,
    studentAnswer: string,
    answerKeyData: { id: number; name: string }[],
    totalMarks: number
  ): number => {
    const preprocessText = (text: string): string[] => {
      return text
        .toLowerCase()
        .replace(/[^\w\s]/g, '') // Remove punctuation
        .split(/\s+/); // Split into words
    };
  
    const countWordMatches = (studentWords: string[], keyWords: string[]): number => {
      return studentWords.filter((word) => keyWords.includes(word)).length;
    };
  
    const processedStudent = preprocessText(studentAnswer);
    let bestMatchPercentage = 0;
  
    answerKeyData.forEach((key) => {
      const processedKeyWords = preprocessText(key.name);
  
      const wordMatchCount = countWordMatches(processedStudent, processedKeyWords);
      const wordMatchPercentage = (wordMatchCount / processedKeyWords.length) * 100;
  
      const combinedAnswer = `${question} ${key.name}`;
      const combinedStudent = `${question} ${studentAnswer}`;
      const overallSimilarity = similarity.compareTwoStrings(
        combinedAnswer.toLowerCase(),
        combinedStudent.toLowerCase()
      ) * 100;
  
      const matchPercentage = Math.max(wordMatchPercentage, overallSimilarity);
  
      if (matchPercentage > bestMatchPercentage) {
        bestMatchPercentage = matchPercentage;
      }
    });
  
    if (bestMatchPercentage >= 90) {
      return totalMarks;
    } else if (bestMatchPercentage >= 50) {
      return totalMarks / 2;
    } else {
      return (bestMatchPercentage / 100) * totalMarks;
    }
  };
  


  const getMark = () => {
    const markData = examQuestionData?.map((item: any) => {
      if (item?.options) {
        if (item?.value) {
          const findOptionData = item?.options?.find((option: any) => option?.name === item?.value)
          if (findOptionData?.answer) {
            return item?.mark
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        const marks = calculateSubjectiveMarks(item?.question, item?.value, item?.answerKeyData, Number(item?.mark));
        return Math.round(marks)
        // return 0;
      }
    })

    const sum = markData.reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0);

    return sum;
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" sx={{ background: '#4CAF50', color: '#FFFFFF' }}>Exam Completed</DialogTitle>
      <DialogContent dividers={true}>
        <ExamCompletedDialogContainer>
            <ExamCompletedDialogHeaderContainer>
                <ExamCompletedDialogHeader>Congratulation!</ExamCompletedDialogHeader>
                <ExamCompletedDialogDescription>You have successfully completed the exam</ExamCompletedDialogDescription>
            </ExamCompletedDialogHeaderContainer>
            <ExamCompletedDialogBodyContainer>
                <ExamCompletedDialogBodyTitle>Marks</ExamCompletedDialogBodyTitle>
                <ExamCompletedMarkContainer>
                    <ExamCompletedPassingMark>{getMark()}</ExamCompletedPassingMark>
                    <ExamCompletedTotalMark>/{examScheduleData?.examData?.totalMark}</ExamCompletedTotalMark>
                </ExamCompletedMarkContainer>
            </ExamCompletedDialogBodyContainer>
        </ExamCompletedDialogContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color='inherit'>Close</Button>
        <Button variant='outlined' onClick={handleClose} color='inherit'>Okay</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExamCompletedDialog