import React, { useState } from 'react';
import './App.css';
import Main from './views/Main/Main';
import MainProfile from './views/MainProfile/MainProfile';

function App() {

  const [isActive, setIsActive] = useState(() => {
    const savedState = localStorage.getItem('isActive');
    return savedState !== null ? JSON.parse(savedState) : false;
  });
  return (
    <>
      {isActive ? <MainProfile /> : <Main />}
    </>
  );
}

export default App;
