import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CachedIcon from '@mui/icons-material/Cached';
import EmergencyRecordingIcon from '@mui/icons-material/EmergencyRecording';
import {
  CandidateImg,
  CandidateName,
  ExamCameraContainer,
  ExamCandidateContainer,
  ExamModuleContainer,
  ExamModuleHeaderContainer,
  ExamName,
  ExamQuestionBodyContainer,
  ExamQuestionBodyWrapper,
  ExamQuestionContainer,
  ExamQuestionCount,
  ExamQuestionFooterContainer,
  ExamQuestionHeaderContainer,
  ExamQuestionListConatiner,
  ExamQuestionNote,
  ExamQuestionRightContainer,
  ExamQuestionWrapper,
  ExamTime,
  Question,
  QuestionAnsInput,
  QuestionAnsInputBoxContainer,
  QuestionContainer,
  QuestionLabel,
  QuestionOptionContainer
} from './ExamModule.styled';
import useExamModule from './useExamModule';
import Webcam from 'react-webcam';
import { useLocation } from 'react-router-dom';
import ExamWarningDialog from '../../dialogs/ExamWarningDialog/ExamWarningDialog';
import ExamCompletedDialog from '../../dialogs/ExamCompletedDialog/ExamCompletedDialog';
import ExamSummaryDialog from '../../dialogs/ExamSummaryDialog/ExamSummaryDialog';
import Student from '../Student';
import { startCountdown } from '../../utils/consts/exam';
import { useEffect, useState } from 'react';
import FullScreenWrapper from '../FullScreenComponent';

const ExamModule = () => {
  const location = useLocation();
  const {
    adminData,
    routeData,
    examQuestionData,
    currentQuestion,
    handleNextQuestion,
    handlePreviousQuestion,
    handleSelectAnswer,
    setCurrentQuestion,
    handleOpenExamCompleteDialog,
    handleCloseExamCompleteDialog,
    openExamCompleteDialog,
    handleSubmit,
    isFullScreen,
    handleExitFullScreen,
    setIsFullScreen
  } = useExamModule();


  const isSubmit = examQuestionData.some((item: any) => item?.value === null);
  const [time, setTime] = useState(Number(routeData?.examData?.examDuration) * 60); // Initialize with duration in seconds

  useEffect(() => {
    const handleTimeUp = () => {
      // Call the function you want to execute when time is up
      onTimeUp();
    };

    // Countdown logic
    if (time > 0) {
      const timer = setInterval(() => {
        setTime(prevTime => {
          if (prevTime === 1) {
            clearInterval(timer);
            handleTimeUp();  // Call when time reaches 00:00
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    } else {
      handleTimeUp(); // This ensures the function is called if time is already 0
    }
  }, [time]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const onTimeUp = () => {
    handleOpenExamCompleteDialog()
    // handleExitFullScreen()
    // handleSubmit()
  };

  return (
    <FullScreenWrapper handleExitFullScreen={() => handleExitFullScreen()} isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen}>
      <ExamModuleContainer>
        <ExamModuleHeaderContainer>
          <ExamName>Test Name: {routeData?.examData?.name}</ExamName>
          <ExamTime><AccessTimeIcon />{formatTime(time)}</ExamTime>
        </ExamModuleHeaderContainer>
        <ExamQuestionWrapper>
          <ExamQuestionContainer>
            <ExamQuestionHeaderContainer>
              <ExamQuestionNote>
                Note: If question does not load do not refresh the browser just refresh only using refresh button
              </ExamQuestionNote>
              <Button variant="outlined" startIcon={<CachedIcon />}>
                Refresh
              </Button>
              <Button variant="outlined" startIcon={<EmergencyRecordingIcon />}>
                Recording...
              </Button>
            </ExamQuestionHeaderContainer>
            <ExamQuestionBodyContainer>
              {examQuestionData?.map((item: any) => {
                if (currentQuestion === item?.index) {
                  return (
                    <ExamQuestionBodyWrapper>
                      <QuestionContainer>
                        <QuestionLabel>Question {item?.index}:</QuestionLabel>
                        <Question>{item?.question}</Question>
                      </QuestionContainer>
                      {item?.options ? (
                        <QuestionOptionContainer>
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={item?.value}
                              onChange={(e) => handleSelectAnswer(item, e)}
                            >
                              {item?.options?.map((option: any) => {
                                return (
                                  <FormControlLabel value={option?.name} control={<Radio />} label={option?.name} />
                                );
                              })}
                            </RadioGroup>
                          </FormControl>
                        </QuestionOptionContainer>
                      ) : (
                        <QuestionAnsInputBoxContainer>
                          <QuestionAnsInput
                            placeholder="Enter your answer here..."
                            value={item?.value}
                            onChange={(e) => handleSelectAnswer(item, e)}
                          />
                        </QuestionAnsInputBoxContainer>
                      )}
                    </ExamQuestionBodyWrapper>
                  )
                }
              })}
            </ExamQuestionBodyContainer>
            <ExamQuestionFooterContainer>
              <Button variant="contained" onClick={handlePreviousQuestion} disabled={currentQuestion === 1 || openExamCompleteDialog}>Previous</Button>
              <Button variant="contained" onClick={handleNextQuestion} disabled={currentQuestion === examQuestionData?.length || openExamCompleteDialog}>Save & Next</Button>
              <Button variant="contained" onClick={() => handleOpenExamCompleteDialog()} color='success' disabled={isSubmit || openExamCompleteDialog}>Submit</Button>
            </ExamQuestionFooterContainer>
          </ExamQuestionContainer>
          <ExamQuestionRightContainer>
            <ExamCandidateContainer>
              <CandidateImg src={adminData?.profileImg}></CandidateImg>
              <CandidateName>{adminData?.firstName + ' ' + adminData?.lastName}</CandidateName>
            </ExamCandidateContainer>
            <ExamQuestionListConatiner>
              {examQuestionData.map((item: any, index: number) => (
                <ExamQuestionCount key={index} isFill={item?.value ? true : false} onClick={() => setCurrentQuestion(item?.index)}>{index + 1}</ExamQuestionCount>
              ))}
            </ExamQuestionListConatiner>
            <ExamCameraContainer>
              <Student />
            </ExamCameraContainer>
          </ExamQuestionRightContainer>
        </ExamQuestionWrapper>

        {/* {true && (
          <ExamWarningDialog open={true} handleClose={() => {}} />
        )} */}

        {openExamCompleteDialog && (
          <ExamCompletedDialog open={openExamCompleteDialog} handleClose={() => handleCloseExamCompleteDialog()} examScheduleData={routeData} examQuestionData={examQuestionData} />
        )}

        {/* {true && (
          <ExamSummaryDialog open={true} handleClose={() => {}} />
        )} */}
      </ExamModuleContainer>
    </FullScreenWrapper>
  );
};

export default ExamModule;
