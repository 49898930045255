import React, { useState, useEffect, useRef } from 'react';
import { serverUpdateExamScheduleStatus } from '../../services/serverApi';
import { ExamStatusType } from '../../types/exam.d';

interface CountdownProps {
  dateString: string;
  timeString: string;
  examScheduleId?: string;
  getExamScheduleData: () => void;
}

const Countdown: React.FC<CountdownProps> = ({ dateString, timeString, examScheduleId, getExamScheduleData }) => {
  const [timeLeft, setTimeLeft] = useState<string>('');
  const [hasEnded, setHasEnded] = useState<boolean>(false);

  // Ref to store the timer ID
  const timerRef = useRef<number | undefined>(undefined);

  const handleUpdateStatus = async () => {
    if (hasEnded) return; // Prevent duplicate API calls
    try {
      const adminDataString = localStorage.getItem('candidate');
      if (adminDataString) {
        const adminData = JSON.parse(adminDataString);
        await serverUpdateExamScheduleStatus(String(examScheduleId), adminData?._id, ExamStatusType?.Pending);
        getExamScheduleData();
      }
    } catch (err) {
      console.error('Error updating exam status:', err);
    }
  };

  const calculateTimeLeft = (dateString: string, timeString: string): { timeString: string; diff: number } => {
    const [year, month, day] = dateString.split('-').map(Number);
    const [hours, minutes] = timeString.split(':').map(Number);
    const targetDate = new Date(year, month - 1, day, hours, minutes, 0, 0);
    const now = new Date();
    const diff = targetDate.getTime() - now.getTime();

    if (diff <= 0) {
      return { timeString: '0 days 00:00:00', diff }; // Handle the case when the target date has passed
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hoursRemaining = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesRemaining = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const secondsRemaining = Math.floor((diff % (1000 * 60)) / 1000);

    return {
      timeString: `${days} days ${hoursRemaining.toString().padStart(2, '0')}:${minutesRemaining
        .toString()
        .padStart(2, '0')}:${secondsRemaining.toString().padStart(2, '0')}`,
      diff,
    };
  };

  useEffect(() => {
    const updateTimeLeft = () => {
      const { timeString: updatedTimeLeft, diff: updatedDiff } = calculateTimeLeft(dateString, timeString);
      setTimeLeft(updatedTimeLeft);

      if (updatedDiff <= 0 && !hasEnded) {
        handleUpdateStatus();
        setHasEnded(true);
        if (timerRef.current) {
          clearInterval(timerRef.current); // Stop the interval when the countdown ends
        }
      }
    };

    // Initial calculation and setting up the interval
    updateTimeLeft();

    if (!hasEnded) {
      timerRef.current = window.setInterval(updateTimeLeft, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current); // Cleanup interval on unmount
      }
    };
  }, [dateString, timeString, hasEnded]);

  return (
    <div>
      <p>{timeLeft}</p>
    </div>
  );
};

export default Countdown;
