import React, { useEffect, useMemo, useState } from 'react'
import { ICandidate } from '../../types/candidate';
import {
  type MRT_ColumnDef,
  type MRT_Row,
  useMaterialReactTable,
} from 'material-react-table';
import { IExamSchedule } from '../../types/exam';
import { Box } from '@mui/material';
import { ExamStatus } from './ProfileDashboard.styled';
import { serverGetRecentExamScheduleByBatchId, serverGetStudentDashboardData } from '../../services/serverApi';
import { getStringDateTime } from '../../utils/consts/exam';
import moment from 'moment';
import { getExamStatusBgColor, getExamStatusColor } from '../../utils/helpers/global';

const useProfileDashboard = () => {
    const [adminData, setAdminData] = useState<ICandidate>();
    const [examScheduleData, setExamScheduleData] = useState<IExamSchedule[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [openNoticeDialog, setOpenNoticeDialog] = useState<boolean>(false);
    const [selectedExamData, setSelectedExamData] = useState<IExamSchedule>();
    const [openProfileMatchDialog, setOpenProfileMatchDialog] = useState<boolean>(false);
    const [dashboardData, setDashboardData] = useState<any>();

    const getExamScheduleData = async () => {
      try {
        setLoading(true);
        const candidateDataString = localStorage.getItem('candidate');
        if (candidateDataString) {
          const candidateData = JSON.parse(candidateDataString);
          const data = await serverGetRecentExamScheduleByBatchId(String(candidateData?.batchId), candidateData?._id);
          setExamScheduleData(data?.data);
        }
      } catch (error) {
        console.log(error);
        setExamScheduleData([]);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    const getDashboardData = async () => {
      try {
        setLoading(true);
        const candidateDataString = localStorage.getItem('candidate');
        if (candidateDataString) {
          const candidateData = JSON.parse(candidateDataString);
          const data = await serverGetStudentDashboardData(String(candidateData?._id?.toString()));
          setDashboardData(data?.data);
        }
      } catch (error) {
        console.log(error);
        setDashboardData(undefined);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    const handleOpenNoticeDialog = (data: any) => {
      setOpenNoticeDialog(true);
      setSelectedExamData(data)
    }

    const handleCloseNoticeDialog = () => {
      setOpenNoticeDialog(false);
      setSelectedExamData(undefined);
    }

    const handleOpenProfileMatchDialog = () => {
      setOpenNoticeDialog(false);
      setOpenProfileMatchDialog(true);
    }

    const handleCloseProfileMatchDialog = () => {
      setOpenProfileMatchDialog(false);
    }

    const columns = useMemo<MRT_ColumnDef<IExamSchedule>[]>(
      () => [
          {
            accessorKey: "name",
            header: "Exam Name",
            Cell: ({ row }: any) => {
            return (
            <Box sx={{ display: 'flex', gap: '2ch', alignItems: 'center' }}>
              {row?.original?.examData?.name}
            </Box>
            )
            },
          },
          {
            accessorKey: "starDate",
            header: "Start Date",
            Cell: ({ row }: any) => {
            return (
            <Box sx={{ display: 'flex', gap: '2ch', alignItems: 'center' }}>
                {getStringDateTime(moment(row?.original?.startDate).format('YYYY-MM-DD'), String(row?.original?.startTime))}
            </Box>
            )
            },
          },
          {
            accessorKey: "endDate",
            header: "End Date",
            Cell: ({ row }: any) => {
            return (
            <Box sx={{ display: 'flex', gap: '2ch', alignItems: 'center' }}>
                {getStringDateTime(moment(row?.original?.endDate).format('YYYY-MM-DD'), row?.original?.endTime)}
            </Box>
            )
            },
          },
          {
            accessorKey: "pasingMark",
            header: "Passing Marks",
            Cell: ({ row }: any) => {
            return (
            <Box sx={{ display: 'flex', gap: '2ch', alignItems: 'center' }}>
                {row?.original?.examData?.passingMark}
            </Box>
            )
            },
          },
          {
            accessorKey: "totalMark",
            header: "Total Marks",
            Cell: ({ row }: any) => {
            return (
            <Box sx={{ display: 'flex', gap: '2ch', alignItems: 'center' }}>
                {row?.original?.examData?.totalMark}
            </Box>
            )
            },
          },
          {
            accessorKey: "status",
            header: "status",
            Cell: ({ row }: any) => {
            return (
            <Box sx={{ display: 'flex', gap: '2ch', alignItems: 'center' }}>
                <ExamStatus bgColor={getExamStatusBgColor(row?.original?.status)} color={getExamStatusColor(row?.original?.status)}>{row?.original?.status}</ExamStatus>
            </Box>
            )
            },
          },
        ],
      []
    );

    const table = useMaterialReactTable({
      columns,
      data: examScheduleData,
      enableEditing: false,
      positionActionsColumn: "last",
      enablePagination: false,
      enableBottomToolbar: false,
      state: { isLoading: loading },
      enableStickyHeader: true,
      muiTableContainerProps: { sx: { maxHeight: "65vh" } },
    });


    useEffect(() => {
      const adminDataString = localStorage.getItem('candidate');
      if (adminDataString) {
        const adminData = JSON.parse(adminDataString);
        setAdminData(adminData)
      } else {
        setAdminData(undefined)
      }
    }, [])

    useEffect(() => {
      getExamScheduleData()
      getDashboardData()
    }, [])

    return {
      adminData,
      table,
      handleCloseNoticeDialog,
      openNoticeDialog,
      handleOpenProfileMatchDialog,
      handleCloseProfileMatchDialog,
      selectedExamData,
      openProfileMatchDialog,
      dashboardData
    }
}

export default useProfileDashboard